<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title>
          Users
          <v-spacer></v-spacer>
          <span class="subtitle-2">
            <v-dialog
              v-model="isDialogVisible"
              persistent
              max-width="600px"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  Add
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline" v-if="userInfo.id === null">New User</span>
                  <span class="headline" v-else>Manage User</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-alert
                    v-if="submitErr !== null"
                    type="error"
                    dense
                    dismissible
                  >
                    {{ submitErr }}
                  </v-alert>
                  <v-form ref="formUser">
                    <v-container>
                      <v-row>
                        <v-col
                          cols="12"
                          lg="6"
                        >
                          <v-text-field
                            v-model="userInfo.name"
                            label="Name"
                            dense
                            outlined
                            hide-details
                            :rules="[required]"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          lg="6"
                        >
                          <v-text-field
                            v-model="userInfo.email"
                            label="Email"
                            dense
                            outlined
                            hide-details
                            :rules="[required, emailValidator]"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          lg="6"
                        >
                          <v-text-field
                            v-model="userInfo.mobile_phone"
                            label="Mobile Phone"
                            dense
                            outlined
                            hide-details
                            :rules="[required]"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-divider class="my-3"></v-divider>
                      <v-row>
                        <v-col
                          cols="12"
                          lg="6"
                        >
                          <v-text-field
                            v-model="userInfo.username"
                            label="Username"
                            dense
                            outlined
                            hide-details
                            :rules="[required]"
                          ></v-text-field>
                        </v-col>
<!--                        <v-col-->
<!--                          cols="12"-->
<!--                          lg="6"-->
<!--                        >-->
<!--                          <v-select-->
<!--                            v-model="userInfo.role"-->
<!--                            :items="[{v: 'user', label: 'User'},{v: 'admin', label: 'Admin'}]"-->
<!--                            item-value="v"-->
<!--                            item-text="label"-->
<!--                            label="Role"-->
<!--                            dense-->
<!--                            outlined-->
<!--                            hide-details-->
<!--                            :rules="[required]"-->
<!--                          ></v-select>-->
<!--                        </v-col>-->
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          lg="6"
                        >
                          <v-text-field
                            v-model="userInfo.password"
                            label="Password"
                            dense
                            outlined
                            hide-details
                            type="password"
                            :rules="[required]"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          lg="6"
                        >
                          <v-text-field
                            v-model="userInfo.confirmPassword"
                            label="Confirm Password"
                            dense
                            outlined
                            hide-details
                            type="password"
                            :rules="[required, confirmedValidator(userInfo.confirmPassword, userInfo.password)]"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn
                    color="error"
                    outlined
                    :disabled="savingUser"
                    @click="closeModal"
                  >
                    Close
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    :loading="savingUser"
                    @click="submitUser"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </span>
        </v-card-title>
        <div>
          <v-data-table
            :headers="headers"
            :items="userList"
            :items-per-page="5"
            :loading="tableLoading"
          >
            <!-- Action -->
            <template #[`item.actions`]="{ item }">
              <v-icon
                color="error"
                small
                @click="deleteItem(item)"
                v-if="myInfo.username !== item.username"
              >
                {{ icons.mdiDelete }}
              </v-icon>
            </template>

            <!-- Role -->
            <template #[`item.role`]="{item}">
              <v-chip
                small
                :color="roleColor[item.role]"
                :class="`${roleColor[item.role]}--text`"
                class="v-chip-light-bg text-capitalize"
              >
                {{ item.role }}
              </v-chip>
            </template>
          </v-data-table>
        </div>
      </v-card>
      <confirm ref="veeConfirmRef"></confirm>
    </v-col>
  </v-row>
</template>
<script>

import {
  ref, reactive, onMounted, computed,
} from '@vue/composition-api'
import { required, confirmedValidator, emailValidator } from '@core/utils/validation'
import { mdiDelete, mdiPencil } from '@mdi/js'
import axios from '@axios'
import store from '@/store'

/* eslint-disable no-underscore-dangle */
export default {
  components: {
    Confirm: () => import('@/components/Confirm.vue')
  },
  setup() {
    const userList = ref([])
    const tableLoading = ref(true)
    const veeConfirmRef = ref(null)
    const isDialogVisible = ref(false)
    const roleColor = {
      admin: 'primary',
      user: 'info',
    }
    const myInfo = computed(() => JSON.parse(localStorage.getItem('userData')) || null)

    const userInfo = reactive({
      id: null,
      username: null,
      mobile_phone: null,
      name: null,
      email: null,
      password: null,
      confirmPassword: null,
      role: 'admin', // Temporary set admin only
    })
    const datePicker = ref(false)

    const loadUserList = () => {
      tableLoading.value = true
      axios
        .get('/user/list')
        .then(response => {
          const { data } = response.data
          userList.value = data
        })
        .finally(() => { tableLoading.value = false })
    }

    const formUser = ref(null)
    const submitErr = ref(null)

    const savingUser = ref(false)
    const submitUser = () => {
      submitErr.value = null
      if (formUser.value.validate()) {
        savingUser.value = true
        axios
          .post('/user/add', {
            username: userInfo.username,
            mobile_phone: userInfo.mobile_phone,
            name: userInfo.name,
            email: userInfo.email,
            password: userInfo.password,
            role: userInfo.role,
          })
          .then(response => {
            const { code, message } = response.data
            if (code !== '0') {
              submitErr.value = message
            } else {
              loadUserList()
              formUser.value.reset()
              isDialogVisible.value = false
            }
          })
          .catch(() => {
            submitErr.value = 'Error while processing'
          })
          .finally(() => { savingUser.value = false })
      }
    }
    const closeModal = () => {
      if (!savingUser.value) {
        isDialogVisible.value = false
        formUser.value.reset()

        return true
      }

      return false
    }

    const deleteItem = user => {
      veeConfirmRef.value
        .open('Deleting User', `Are you sure to delete user '${user.name}' ?`, { color: 'error' })
        .then(confirm => {
          if (confirm === true) {
            axios
              .post('/user/delete', {
                user_id: [user.user_id],
              })
              .then(response => {
                const { code, message } = response.data
                if (code === '0') {
                  loadUserList()
                } else {
                  // Todo: display error
                }
              })
          }
        })
    }

    onMounted(() => {
      loadUserList()
      store.dispatch('sites/fetchSites')
    })

    return {
      headers: [
        { text: 'USERNAME', value: 'username' },
        { text: 'NAME', value: 'name' },
        { text: 'EMAIL', value: 'email' },
        // {
        //   text: 'ROLE', value: 'role', align: 'center', sortable: false,
        // },
        { text: 'UPDATE DATE', value: 'update_date' },
        {
          text: 'ACTIONS', value: 'actions', align: 'center', sortable: false,
        },
      ],
      userList,
      tableLoading,
      veeConfirmRef,
      isDialogVisible,
      roleColor,

      userInfo,
      savingUser,
      datePicker,
      formUser,
      submitErr,

      submitUser,
      closeModal,
      deleteItem,
      myInfo,

      icons: {
        mdiDelete,
        mdiPencil,
      },
      required,
      confirmedValidator,
      emailValidator,
    }
  },
}
</script>
